<template>
  <div class="row justify-content-center">
    <div class="col-md-9">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">Dados do Plano</h3>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="form-group  col-md-5">
                <label class="col-md-12">Nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o Nome"
                />
              </div>
              <div class="form-group  col-md-2">
                <label class="col-md-12">Regra:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.regra_mensalidade"
                  placeholder="Digite a Regra Mensalidade"
                />
              </div>
              <div class="form-group  col-md-2">
                <label class="col-md-12">Valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-money="moneyConfig"
                  v-model="valor"
                  placeholder="R$ 0,00"
                />
              </div>
              <div class="col-md-3">
              <label class="col-md-12">D. vencimento:*</label>
              <input
                required=""
                type="date"
                class="form-control"
                id="form_control_1"
                v-model="form.data_vencimento"
                placeholder="Data de vencimento..."
              />
            </div>
              
      
              <div class="col-md-12" style="margin-top: -30px;">
                <label class="col-md-12">Descrição:*</label>
                <textarea
                
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a descrição"
                />
              
            </div>
            <div class="row form-group col-md-12">
            <div class="col-md-3 pull-left">
              <div class="md-radio-inline">
                <label>Status:*</label>
                <div class="space d-flex">
                  <b-form-radio :inline="true" value="1" v-model="form.status">
                    Ativo
                  </b-form-radio>

                  <b-form-radio :inline="true" value="2" v-model="form.status">
                    Desativado
                  </b-form-radio>

                  <b-form-radio :inline="true" value="3" v-model="form.status">
                    Suspenso
                  </b-form-radio>
                </div>
              </div>
            </div>
          </div>
          </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="confirm(!lista_campos.id ? 'cria' : 'edita')"
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contratos" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        valor:  "",
        regra_mensalidade: null,
        descricao: "",
        data_vencimento:null
      },
    
      verif: false,
      currentPage: 1,
      perPage: 10,
      editarProduto: true,
      valor:'',
      moneyConfig: {
        prefix: 'R$ ',
        thousands: '.',
        decimal: ',',
        precision: 2, // Defina o número de casas decimais aqui
        allowBlank: true // Permite que o campo esteja vazio
      }
    };
  },
  created() {
    this.preenxerCampos()
  },
  computed: {
 

    lista_campos() {
      // return JSON.parse(localStorage.getItem("produto"));
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
  },
  watch:{
 
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Produto no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      
      this.form.valor =  parseFloat(this.valor.replace('R$', '').replace(',','.') ) 
      // console.log( this.form )
      this.verif = true;
      await this.$store.dispatch("produto/create_produto", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "produto",
      });
    },
    async update() {
        this.verif = true;
      await this.$store.dispatch("produto/update_produto", this.form);
      this.verif = false;

        this.fireAlert({
        ...this.mensagem_alert,
        routeName: "produto",
      });
    },

  
    async preenxerCampos() {
      console.log(this.lista_campos);

    if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          // valor: this.lista_campos.valor,
          regra_mensalidade: this.lista_campos.regra_mensalidade,
          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          data_vencimento: this.lista_campos.data_vencimento,


        };
        this.valor= this.lista_campos.valor

    }
    }
    
  

};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>